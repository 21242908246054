import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenKey = 'authToken';
  private userKey = 'authUser';
  private loggedIn = new BehaviorSubject<boolean>(false);
  private apiURL = environment.apiURL;
  private user = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.loggedIn.next(!!this.getToken() && !!this.getUser());
  }

  login(credentials: { mobile_no: string; password: string }): Observable<any> {
    const url = `${this.apiURL}/login`;

    return this.http.post<any>(url, credentials).pipe(
      map((response: any) => {
        this.setToken(response?.token);
        console.log('Logging response on login -- ', response.data);

        const userData = response.data;

        if (userData.img_path) {
          userData.img_path =  environment.serverURL + userData.img_path.replace("uploads\\", "\\"); 
          // userData.img_path = userData.img_path.replace(/\\/g, '/');
        }
        this.setUser(response?.data);
        this.loggedIn.next(true);
        this.user.next(response?.data);
        return response;
      })
    );
  }

  signup(reqBody: any): Observable<any> {
    const url = `${this.apiURL}/signup`;
    return this.http.post(url, reqBody).pipe(
      map((response: any) => {
        this.setToken(response?.token);
        this.setUser(response?.data);
        console.log('Logging response on signup -- ', response?.data);

        this.loggedIn.next(true);
        this.user.next(response?.data);
        return response;
      })
    );
  }

  logout(): void {
    this.removeToken();
    this.removeUser();
    this.loggedIn.next(false);
    this.user.next(null);
    this.router.navigate(['/']);
  }

  private setUser(user: any): void {
    // this.cookieService.set(this.userKey, JSON.stringify(user), {
    //   path: '/',
    //   secure: true,
    //   sameSite: 'Strict',
    // });
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(this.userKey, JSON.stringify(user));
    }
  }

  getUser(): any {
    // const user = this.cookieService.get(this.userKey);

    if (typeof window !== 'undefined') {
      const user2 = sessionStorage.getItem(this.userKey);
      // console.log('------>User <--------------- ', user2);
      // if (user2 != null) {
      //   console.log('User found from session storage -- ', JSON.parse(user2));
      // }
      // this.getImageUrl();

      return user2 ? JSON.parse(user2) : null;
    }
    return null;
  }

  private removeUser(): void {
    // this.cookieService.delete(this.userKey, '/');
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem(this.userKey);
    }
  }

  private setToken(token: string): void {
    // this.cookieService.set(this.tokenKey, token, {
    //   path: '/',
    //   secure: true,
    //   sameSite: 'Strict',
    // });
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(this.tokenKey, token);
    }
  }

  getToken(): string | null {
    if (typeof window !== 'undefined') {
      // console.log('Token -- ', sessionStorage.getItem(this.tokenKey));

      return (
        // this.cookieService.get(this.tokenKey) ||
        sessionStorage.getItem(this.tokenKey) || null
      );
    }
    return null;
  }

  private removeToken(): void {
    // this.cookieService.delete(this.tokenKey, '/');
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem(this.tokenKey);
    }
  }

  isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  getUserInfo(): Observable<any> {
    return this.user.asObservable();
  }

  updateUser(user: any): void {
    this.removeUser();
    this.setUser(user);
  }

  //p
  uploadUserProfilePic(formData: any): Observable<any> {
    console.log(formData);

    return this.http.post(`${this.apiURL}/images`, formData);
  }

  getProfileImgPath(userId: number): Observable<any> {
    return this.http.get(`${this.apiURL}/images/${userId}`);
  }

  ///////////////////////////////ADDED Below

  private profilePicUrl = new BehaviorSubject<string>('');

  getImageUrl(): Observable<string> {
    console.log("user info");

    const user = this.getUser();
    console.log(user);

    this.getProfileImgPath(user.user_id).subscribe({
      next: (data) => {
        let extractedProfileUrl: string = data.imgPath[0].img_path;
        extractedProfileUrl = extractedProfileUrl.replace("uploads\\", "\\");
        this.profilePicUrl.next(environment.serverURL + extractedProfileUrl);//need to check
        console.log(data.imgPath[0].img_path);

        console.log("ProfilepicURL>>>>");

        console.log(this.profilePicUrl);
      },
      error: (err) => {
        console.log("img path is empty");

        throw (err)
      }
    });
    return this.profilePicUrl.asObservable();
  };

  ///////////////////////////////ADDED ABOVE

  getUserInfoByID(userId: number): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/userInfo`, { user_id: userId });
  }

  getUserNameByID(userId: number): Observable<any> {
    console.log("Inside services");

    return this.http.post<any>(`${this.apiURL}/userInfo`, { user_id: userId }).pipe(map(response => response.avatar_name));
  }

  sendOtp(reqBody: any) {
    return this.http.post<any>(`${this.apiURL}/sendOtp`, reqBody);
  }

  verifyOtp(reqBody: any) {
    return this.http.post<any>(`${this.apiURL}/verifyOtp`, reqBody);
  }

  resetPassword(reqBody: any) {
    return this.http.post<any>(`${this.apiURL}/resetPassword`, reqBody);
  }
}
